import { t } from "i18next";

export const getNavigationLinks = () => [
  {
    label: t("navigation.Backlinks"),
    destination: "/",
  },
  {
    label: t("navigation.Backlink Focus Keyword"),
    destination: "/backlinkKeyword",
  },
  {
    label: t("navigation.Backlink Signup Details"),
    destination: "/BacklinkSignupDetails",
  },
  {
    label: t("navigation.Price"),
    destination: "/pricing",
  },
  {
    label: t("navigation.Settings"),
    destination: "/settings",
  },
];
