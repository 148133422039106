import React, { useCallback, useState } from "react";
import { Divider, Page, Tabs } from "@shopify/polaris";
import { t } from "i18next";
import PriceInfo from "./PriceInfo";
import Pricing from "./Pricing";

const getPageTypeTabs = () => [
  {
    id: "Pricing",
    content: t("pricing.Pricing"),
  },
  {
    id: "Plan Info",
    content: t("pricing.Plan Info"),
  },
];

export default function PricingTab() {
  const pageTypeTabs = getPageTypeTabs();
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
  return (
    <Page
      title={t("pricing.Pricing")}
      titleMetadata={
        <Tabs tabs={pageTypeTabs} selected={selected} onSelect={handleTabChange} disclosureText="More views" />
      }
    >
      <Divider borderColor="border" />

      {selected === 0 && <Pricing hideTitle={true} />}
      {selected === 1 && <PriceInfo />}
    </Page>
  );
}
