import React from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import AdminPublicRoute from "@/Components/Common/AdminPublicRoute.jsx";
import DeletedUser from "@/Pages/Admin/DeletedUser.jsx";
import GetData from "@/Pages/Admin/GetData.jsx";
import Login from "@/Pages/Admin/Login.jsx";
import User from "@/Pages/Admin/User.jsx";
import BacklinkSites from "./Pages/Admin/BacklinkSites";

function AdminRouteWrapper() {
  return (
    <AdminPublicRoute>
      <ReactRouterRoutes>
        <Route path="/admin/user" element={<User />} />
        <Route path="/admin/deleteuser" element={<DeletedUser />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/backlinkSites" element={<BacklinkSites />} />
        <Route path="/admin/getdata" element={<GetData />} />
      </ReactRouterRoutes>
    </AdminPublicRoute>
  );
}

export default AdminRouteWrapper;
