import React, { useCallback, useRef } from "react";
import { Card, Text } from "@shopify/polaris";
import { t } from "i18next";
import CommonTable from "@/Components/Common/CommonTable";
import { getValue } from "@/Utils/Index";

const priceInfoTableOption = [
  {
    type: "index",
  },
  {
    type: "price",
    value: "planPrice",
  },
  {
    type: "key",
    value: "planName",
  },
  {
    type: "date",
    value: "startDate",
  },
];

export default function PriceInfo() {
  const childRef = useRef();
  const rowData = useCallback((rows) => {
    const values = [];
    rows.map((row, index) => {
      let value = [];
      priceInfoTableOption.forEach((Option) => {
        if (Option.type === "index") {
          return value.push(<Text>{index + 1}</Text>);
        }
        if (Option.type === "price") {
          return value.push(<Text>${row.planPrice} </Text>);
        }
        return value.push(getValue(row, Option));
      });
      values.push(value);
    });
    return values;
  }, []);

  return (
    <>
      <br />
      <Card>
        <Text variant="headingMd" as="h5">
          {t("pricing.Pricing Plan Info")}
        </Text>
        <br />
        <CommonTable
          title={t(`pricing.Plan`)}
          url={`plan/getPriceInfo`}
          rowsData={rowData}
          headings={[t(`pricing.Index`), t(`pricing.Price`), t(`pricing.planName`), t(`pricing.Created`)]}
          ref={childRef}
          columnWidths={["300px", "300px", "300px", "300px"]}
          columnContentTypes={["string", "string", "string", "string"]}
          isAdd={false}
          verticalAlign="middle"
          isSearchVisible={false}
          isPaginationVisible={false}
          isPaginationWithCount={false}
        />
      </Card>
    </>
  );
}
