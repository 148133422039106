import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Box, Button, Card, Divider, Icon, InlineStack, Text, Tooltip } from "@shopify/polaris";
import { CircleTickMajor, ClockMajor, DiamondAlertMajor, DiscountsMinor } from "@shopify/polaris-icons";
import { t } from "i18next";
import { promocodeFormFields } from "@/Assets/Mocks/CommonPricing.mock";
import CommonForm from "@/Components/Common/CommonForm";

const Discount = ({ props }) => {
  const {
    plan,
    formRef,
    formValues,
    upgradePlan,
    cancelPlan,
    submitPromocode,
    trialDays,
    profileData,
    urlPromoCode,
    selectedPlan,
    minuteTrialText,
  } = props;
  const [promoFormValue, setPromoFormValue] = useState(formValues);
  const { t } = useTranslation();
  promocodeFormFields[0].placeholder = t(`pricing.Enter Promocode`);

  let trialText =
    profileData.trial_days === undefined || trialDays === 7
      ? t(`pricing.7 days free trial, No Credit card required`)
      : trialDays > 0 && trialDays < 7
      ? `${t("pricing.Your remaining trial days are")} ${trialDays}. ${t("pricing.You have used")} ${
          7 - trialDays
        } ${t("pricing.days.")}`
      : t(`pricing.No trial days remaining. You have already used the 7-day free trial.`);

  return (
    <React.Fragment>
      {plan.discountLabel && (
        <InlineStack blockAlign="center" align="space-between">
          <Text variant="headingSm" as="h3" tone="subdued">
            <span className="line-through">${plan.monthlyPrice ? plan.monthlyPrice : plan.price}</span>
          </Text>
          <InlineStack gap="100" blockAlign="center" wrap={false}>
            <Icon source={DiscountsMinor} tone="success" />
            {plan.discountValue ? (
              <Text as="p" tone="success">
                {plan.discountLabel} {t(`pricing.Discount applied`)}
              </Text>
            ) : (
              <Text as="p" tone="success">
                {Math.round(100 - (plan.finalPrice * 100) / (plan.monthlyPrice || plan.price)) + "%"}{" "}
                {t(`pricing.Discount applied`)}
              </Text>
            )}
          </InlineStack>
        </InlineStack>
      )}
      {!plan.isPromoInputHidden && !plan.selected && (
        <InlineStack blockAlign="center" gap={2} align="space-between">
          <CommonForm
            onSubmit={submitPromocode}
            initialValues={promoFormValue}
            formFields={promocodeFormFields}
            formRef={formRef}
            isSave={false}
            onFormChange={(e) => setPromoFormValue(e)}
          />
          <Button small onClick={() => submitPromocode(plan)}>
            {t(`pricing.Apply`)}
          </Button>
        </InlineStack>
      )}
      {plan.touched && plan.discountLabel && (
        <Text tone="success">
          {t(`pricing.Your code(s)`)} {plan["discountValue"]}{" "}
          {t(`pricing.and discount have been applied successfully`)}
        </Text>
      )}
      {plan.touched && !plan.discountValue && !promoFormValue?.promocode && (
        <InlineStack gap="200" blockAlign="center" wrap={false}>
          <Icon source={DiamondAlertMajor} tone="critical" />
          <Text tone="critical"> {t(`pricing.Your entered promocode is not correct, Enter a valid code`)}</Text>
        </InlineStack>
      )}

      {plan.selected && plan.type === "recurring" && (
        <InlineStack gap="100" blockAlign="center" wrap={false}>
          <Icon source={ClockMajor} tone="base" />
          <Text as="p">
            {minuteTrialText} {t(`pricing.trial left`)}
          </Text>
        </InlineStack>
      )}
      {plan.type === "recurring" &&
        (plan?.disableActiveButton ? (
          <Tooltip content={t("pricing.disableButtonTooltip")} width="wide">
            <Button variant="primary" fullWidth disabled={true}>
              {t("pricing.Upgrade your plan now")}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="primary"
            fullWidth
            onClick={() => (plan.selected ? cancelPlan(plan) : upgradePlan(plan))}
          >
            {plan.trial && !plan.selected
              ? trialDays === 0 && profileData.trial_days !== undefined
                ? t("pricing.Upgrade your plan now")
                : `${t("pricing.Start")} ${profileData.trial_days === undefined ? plan.trial.days : trialDays} ${t(
                    "pricing.Day Free Trial"
                  )}`
              : t(`pricing.Cancel Plan`)}
          </Button>
        ))}

      {plan.type === "onetime" && !plan.selected && (
        <Button variant="primary" tone="success" fullWidth onClick={() => upgradePlan(plan)}>
          {selectedPlan?.id === "Free" || selectedPlan?.planPrice != plan.price || plan?.id === "Consulting"
            ? t("pricing.Upgrade")
            : t("pricing.Buy again")}
        </Button>
      )}
      {plan.trial && !plan.type === "onetime" && (
        <Text variant="headingXs" as="h6" alignment="center">
          {trialText}
        </Text>
      )}
    </React.Fragment>
  );
};

export default function SinglePlan(props) {
  const { t } = useTranslation();
  const {
    plan,
    formRef,
    formValues,
    upgradePlan,
    cancelPlan,
    submitPromocode,
    trialDays,
    profileData,
    urlPromoCode,
    selectedPlan,
  } = props;

  useEffect(() => {
    if (typeof plan.isPromoInputHidden === "string") {
      plan.isPromoInputHidden = !eval(plan.isPromoInputHidden);
    }
  }, [profileData, plan]);
  return (
    <div className="pricing-card">
      <Card padding="400" background={"bg-surface"}>
        <BlockStack gap="400">
          <InlineStack blockAlign="center" align="space-between">
            <Text variant="headingLg" as="h2">
              {plan.name}
            </Text>
            {plan.selected && (
              <Badge tone="info">
                <Text variant="bodyMd">{t(`pricing.Current`)}</Text>
              </Badge>
            )}
          </InlineStack>
          {plan.id !== "Free" && (
            <InlineStack blockAlign="center">
              <Text variant="heading4xl" as="h3">
                ${plan.finalPrice}
              </Text>
            </InlineStack>
          )}

          {plan.price !== 0 && <Discount props={props} />}
          <Divider></Divider>
          <BlockStack gap={plan.id === "Free" ? "200" : "400"}>
            <InlineStack gap={plan.id === "Free" ? "200" : "500"}>
              {plan.selected && plan.price === 25
                ? plan.oldFeatures.map((feature, index) => (
                    <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                      <Icon size="large" source={CircleTickMajor} tone="success" className="custom-icon" />
                      <Text wrap as="p">
                        {t(`pricing.features.${feature.text}`)}
                      </Text>
                    </InlineStack>
                  ))
                : plan.features.map((feature, index) => (
                    <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                      <Icon size="large" source={CircleTickMajor} tone="success" className="custom-icon" />
                      <Text wrap as="p">
                        {t(`pricing.features.${feature.text}`)}
                      </Text>
                    </InlineStack>
                  ))}
            </InlineStack>
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
}
