import { t } from "i18next";

export const customBasicPlanFeatures = [
  {
    text: "Generate 125 to 150 backlinks every month",
    icon: "",
  },
  {
    text: "20H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Question & Answer Link Building",
    icon: "",
  },
  {
    text: "Image Sharing",
    icon: "",
  },
];

export const customPremiumPlanFeatures = [
  {
    text: "Generate 275 to 300 backlinks every month",
    icon: "",
  },
  {
    text: "40H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Business Listings",
    icon: "",
  },
  {
    text: "Local Listings",
    icon: "",
  },
  {
    text: "Ratings & Reviews",
    icon: "",
  },
];

export const customProPlanFeatures = [
  {
    text: "Generate 550 to 600 backlinks every month",
    icon: "",
  },
  {
    text: "80H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Business Listings",
    icon: "",
  },
  {
    text: "Classified Ads",
    icon: "",
  },
  {
    text: "Article Writing",
    icon: "",
  },
  {
    text: "Article Submission",
    icon: "",
  },
  {
    text: "Blog Creation",
    icon: "",
  },
  {
    text: "Blog Posts",
    icon: "",
  },
];

export const discountCodes = [
  {
    name: "Promotional",
    code: "OS1HORJP",
    type: "percentage", // can be (amount|percentage)
    value: 5,
    interval: 3,
  },
  {
    name: "Promotional",
    code: "ME5DITAM",
    type: "percentage", // can be (amount|percentage)
    value: 10,
  },
  {
    name: "Promotional",
    code: "RU4LIX",
    type: "percentage", // can be (amount|percentage)
    value: 15,
  },
  {
    name: "Promotional",
    code: "SO2ULATW",
    type: "percentage", // can be (amount|percentage)
    value: 20,
  },
];

export const CustomPlansData = [
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "For 3 Months",
    interval: "",
    id: "SEO_Basic",
    name: "SEO Basic",
    price: 750,
    isHidden: false,
    isPromoInputHidden: true,
    features: [...customBasicPlanFeatures],
    discounts: [...discountCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "For 3 Months",
    interval: "",
    id: "SEO_Premium",
    name: "SEO Premium",
    price: 1500,
    isHidden: false,
    isPromoInputHidden: false,
    features: [...customPremiumPlanFeatures],
    discounts: [...discountCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "For 3 Months",
    interval: "",
    id: "SEO_Pro",
    name: "SEO Pro",
    price: 3000,
    isHidden: false,
    isPromoInputHidden: false,
    features: [...customProPlanFeatures],
    discounts: [...discountCodes],
    badge: {
      text: "POPULAR",
    },
  },
];
