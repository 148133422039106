import { differenceInCalendarMonths, differenceInDays, differenceInYears, formatDistanceToNow } from "date-fns";
import { t } from "i18next";
import { ABLogo, CCLogo, LinkifyLogo, SeoLogo } from "@/Assets/Index";

export const paidFeatures = [
  {
    text: "Generate 150+ High quality backlinks",
    icon: "",
  },
  {
    text: "Designed for store with 25+ products.",
    icon: "",
  },
  {
    text: "We'll create blog content related with your website, with a focus on relevant keywords",
    icon: "",
  },
  {
    text: "Enhance site visibility with premium backlinks from content blog posts, each boasting a DA of 30 or higher",
    icon: "",
  },
  {
    text: "We generate 40% backlinks through relevant blog post and targeted keywords and 60% will be social bookmarking, image sharing, profile links Etc",
    icon: "",
  },
  {
    text: "Priority live chat support",
    icon: "",
  },
];
export const oldFeatures = [
  {
    text: "Generate 25 backlinks",
    icon: "",
  },
  {
    text: "24/7 Support",
    icon: "",
  },
  {
    text: "Generate high-quality backlinks with a DA of 50 or above to enhance your website's visibility",
    icon: "",
  },
  {
    text: "Backlinks generate with various categories like social bookmarking, profile links Etc",
    icon: "",
  },
];

export const customBasicPlanFeatures = [
  {
    text: "Generate 125 to 150 backlinks every month",
    icon: "",
  },
  {
    text: "20H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Question & Answer Link Building",
    icon: "",
  },
  {
    text: "Image Sharing",
    icon: "",
  },
];

export const customPremiumPlanFeatures = [
  {
    text: "Generate 275 to 300 backlinks every month",
    icon: "",
  },
  {
    text: "40H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Business Listings",
    icon: "",
  },
  {
    text: "Local Listings",
    icon: "",
  },
  {
    text: "Ratings & Reviews",
    icon: "",
  },
];

export const customProPlanFeatures = [
  {
    text: "Generate 550 to 600 backlinks every month",
    icon: "",
  },
  {
    text: "80H work every month",
    icon: "",
  },
  {
    text: "Manual directory submission",
    icon: "",
  },
  {
    text: "Social Bookmarking",
    icon: "",
  },
  {
    text: "Blog Commenting",
    icon: "",
  },
  {
    text: "Profile Creation",
    icon: "",
  },
  {
    text: "Forums Posting",
    icon: "",
  },
  {
    text: "Business Listings",
    icon: "",
  },
  {
    text: "Classified Ads",
    icon: "",
  },
  {
    text: "Article Writing",
    icon: "",
  },
  {
    text: "Article Submission",
    icon: "",
  },
  {
    text: "Blog Creation",
    icon: "",
  },
  {
    text: "Blog Posts",
    icon: "",
  },
];

export const freeFeatures = [
  {
    text: "Receive 3 automated backlinks with a DA of 20+",
    icon: "",
  },

  {
    text: "Get quick assistance with our live chat",
    icon: "",
  },
  {
    text: "Schedule a one-on-one call with our SEO team for any questions, clarifications, or personalized assistance",
    icon: "",
  },
];

export const paid500Features = [
  {
    text: "Generate 350+ high-quality backlinks (30+ DA)",
    icon: "",
  },
  {
    text: "Designed for store with 50+ products.",
    icon: "",
  },
  {
    text: "Targeting 15 relevant long-tail keywords.",
    icon: "",
  },
  {
    text: "Highlight 10 page/product URLs in blog posts for strategic exposure.",
    icon: "",
  },
  {
    text: "40% backlinks from relevant blog posts, 60% social bookmarking, etc.",
    icon: "",
  },
  {
    text: "Review meeting bi-weekly",
    icon: "",
  },
  {
    text: "Detail report",
    icon: "",
  },
  {
    text: "Live chat support",
    icon: "",
  },
];

export const paid1000Features = [
  {
    text: "Generate 850+ high-quality backlinks (30+ DA)",
    icon: "",
  },
  {
    text: "Designed for store with 100+ products.",
    icon: "",
  },
  {
    text: "Targeting 25 relevant long-tail keywords, main categories, products, sub-categories keywords.",
    icon: "",
  },
  {
    text: "Highlight 20 page/product URLs in blog posts for strategic exposure.",
    icon: "",
  },
  {
    text: "40% backlinks from relevant blog posts, 60% social bookmarking, etc.",
    icon: "",
  },
  {
    text: "Review meeting bi-weekly",
    icon: "",
  },
  {
    text: "Detail report",
    icon: "",
  },
  {
    text: "Live chat support",
    icon: "",
  },
];

export const SEOStrategyConsulting = [
  {
    text: "300+ Backlinks creation",
    icon: "",
  },
  {
    text: "Technical SEO Audit",
    icon: "",
  },
  {
    text: "On-Page Optimization Report",
    icon: "",
  },
  {
    text: "Keyword Research & Analysis",
    icon: "",
  },
  {
    text: "Competitor analysis",
    icon: "",
  },
  {
    text: "20 - 30 Keyword focus",
    icon: "",
  },
  {
    text: "15 Products page optimisation with content",
    icon: "",
  },
];

export const featureList = {
  Free: freeFeatures,
  Premium: paid500Features,
  Premium100: paidFeatures,
  SEO_Basic: customBasicPlanFeatures,
  SEO_Premium: customPremiumPlanFeatures,
  SEO_Pro: customProPlanFeatures,
  Basic: paidFeatures,
  Pro: paid1000Features,
  "Default-feature": paidFeatures,
};
export const interval = {
  Premium: "",
  Premium100: "",
  Default: "",
  Basic: "",
  Pro: "",
};

export const basicCodes = [
  {
    name: "Promotional",
    code: "PE4ACEN",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "TG3KSE",
    type: "percentage", // can be (amount|percentage)
    value: 10,
  },
  {
    name: "Promotional",
    code: "RU4LTX",
    type: "percentage", // can be (amount|percentage)
    value: 15,
  },
  {
    name: "Promotional",
    code: "BX5JAK",
    type: "percentage", // can be (amount|percentage)
    value: 20,
  },
  {
    name: "Promotional",
    code: "IN0NESDP",
    type: "percentage", // can be (amount|percentage)
    value: 25,
  },
  {
    name: "Promotional",
    code: "AW3AKRPS",
    type: "percentage", // can be (amount|percentage)
    value: 30,
  },
];
export const premiumCodes = [
  {
    name: "Promotional",
    code: "KBFYTIE",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "NHDFHD",
    type: "percentage", // can be (amount|percentage)
    value: 10,
  },
  {
    name: "Promotional",
    code: "MNHLOS",
    type: "percentage", // can be (amount|percentage)
    value: 15,
  },
  {
    name: "Promotional",
    code: "VNHSBS",
    type: "percentage", // can be (amount|percentage)
    value: 20,
  },
  {
    name: "Promotional",
    code: "BNROBD",
    type: "percentage", // can be (amount|percentage)
    value: 25,
  },
  {
    name: "Promotional",
    code: "CAYUFD",
    type: "percentage", // can be (amount|percentage)
    value: 30,
  },
];
export const proCodes = [
  {
    name: "Promotional",
    code: "BNUDRE",
    type: "percentage", // can be (amount|percentage)
    value: 5,
  },
  {
    name: "Promotional",
    code: "LM2JWP",
    type: "percentage", // can be (amount|percentage)
    value: 10,
  },
  {
    name: "Promotional",
    code: "HD7NFX",
    type: "percentage", // can be (amount|percentage)
    value: 15,
  },
  {
    name: "Promotional",
    code: "YX8ZPT",
    type: "percentage", // can be (amount|percentage)
    value: 20,
  },
  {
    name: "Promotional",
    code: "QK5DMR",
    type: "percentage", // can be (amount|percentage)
    value: 25,
  },
  {
    name: "Promotional",
    code: "FN3BHU",
    type: "percentage", // can be (amount|percentage)
    value: 30,
  },
];
export const getPlansData = () => [
  {
    type: "free",
    id: "Free",
    name: "Free",
    price: 0,
    isHidden: false,
    features: [...freeFeatures],
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Consulting",
    name: "SEO Strategy & Consulting",
    price: 500,
    backlink: 300,
    isHidden: false,
    isPromoInputHidden: true,
    features: [...SEOStrategyConsulting],
    oldFeatures: [...oldFeatures],
    discounts: [...basicCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Basic",
    name: "Basic",
    price: 250,
    backlink: 150,
    isHidden: false,
    isPromoInputHidden: "profileData?.isPromoCode",
    features: [...paidFeatures],
    oldFeatures: [...oldFeatures],
    discounts: [...basicCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Premium",
    name: "Premium",
    price: 500,
    backlink: 350,
    isHidden: false,
    isPromoInputHidden: "profileData?.isPromoCode",
    features: [...paid500Features],
    discounts: [...premiumCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Pro",
    name: "Pro",
    price: 1000,
    backlink: 850,
    isHidden: false,
    isPromoInputHidden: false,
    features: [...paid1000Features],
    discounts: [...proCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Basic",
    name: "Basic",
    price: 250,
    backlink: 150,
    isHidden: "selectedPlan.id !== 'Basic'",
    isPromoInputHidden: "profileData?.isPromoCode",
    features: [...paidFeatures],
    oldFeatures: [...oldFeatures],
    discounts: [...basicCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Premium",
    name: "Premium",
    price: 500,
    backlink: 350,
    isHidden: "selectedPlan.id !== 'Premium'",
    isPromoInputHidden: "profileData?.isPromoCode",
    features: [...paid500Features],
    discounts: [...premiumCodes],
    badge: {
      text: "POPULAR",
    },
  },
  {
    type: "onetime",
    is_recurring: false,
    intervalLable: "Onetime",
    interval: "",
    id: "Pro",
    name: "Pro",
    price: 1000,
    backlink: 850,
    isHidden: "selectedPlan.id !== 'Pro' ",
    isPromoInputHidden: false,
    features: [...paid1000Features],
    discounts: [...proCodes],
    badge: {
      text: "POPULAR",
    },
  },
];
export const promocodeFormFields = [
  {
    id: "promocode",
    name: "promocode",
    labelPosition: "right",
    type: "text",
    errormsg: "Promocode is required",
    placeholder: "Enter Promocode",
  },
];

export const initialValues = {
  promocode: "",
};

export const formFieldsCancelReason = [
  {
    id: "cancelReason",
    name: "cancelReason",
    label: "Please Choose a Cancellation Reason",
    nested: "object",
    groupSize: 2,
    section: false,
    subfields: [
      {
        radioId: "noLongerNeeded",
        id: "noLongerNeeded",
        name: "reason",
        label: "No Longer Needed?",
        type: "radio",
      },
      {
        radioId: "dontWork",
        id: "dontWork",
        name: "reason",
        label: "Didn't Meet Expectations",
        type: "radio",
      },
      {
        radioId: "costConcerns",
        id: "costConcerns",
        name: "reason",
        label: "Cost Concerns",
        type: "radio",
      },
      {
        radioId: "technicalIssues",
        id: "technicalIssues",
        name: "reason",
        label: "Technical Issues",
        type: "radio",
      },
      {
        radioId: "foundAlternative",
        id: "foundAlternative",
        name: "reason",
        label: "Found an Alternative",
        type: "radio",
      },
      {
        radioId: "complexity",
        id: "complexity",
        name: "reason",
        label: "Complexity",
        type: "radio",
      },
      {
        radioId: "lackFeatures",
        id: "lackFeatures",
        name: "reason",
        label: "Lack of Features",
        type: "radio",
      },
      {
        radioId: "poorCustomerSupport",
        id: "poorCustomerSupport",
        name: "reason",
        label: "Poor Customer Support",
        type: "radio",
      },
      {
        radioId: "businessClosure",
        id: "businessClosure",
        name: "reason",
        label: "Business Closure or Change",
        type: "radio",
      },
      {
        radioId: "temporaryPause",
        id: "temporaryPause",
        name: "reason",
        label: "Temporary Pause",
        type: "radio",
      },
      {
        radioId: "performanceIssues",
        id: "performanceIssues",
        name: "reason",
        label: "Performance Issues",
        type: "radio",
      },
      {
        radioId: "other",
        id: "other",
        name: "reason",
        label: "Other",
        type: "radio",
      },
      {
        id: "value",
        name: "value",
        label: "Mention Your Specific Reason Here",
        labelPosition: "left",
        type: "text",
      },
    ],
  },
];

export const cancelReasonInitialValues = {
  cancelReason: {
    reason: "",
    value: "",
  },
};

const dateWisePrice = (date, billingInterval) => {
  const day = differenceInDays(new Date(), new Date(date));
  if (day <= 60) {
    return billingInterval === "Year" ? 3.99 : 6.99;
  } else if (day > 60 && day <= 180) {
    return billingInterval === "Year" ? 4.99 : 7.99;
  } else if (day > 180 && day <= 365) {
    return billingInterval === "Year" ? 5.99 : 8.99;
  } else {
    return billingInterval === "Year" ? 6.99 : 9.49;
  }
};

export const dateWisePriceObj = (date, plan) => {
  const { billingInterval } = plan;
  const monthlyPrice = 9.99;
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 9.99);
  let finalObj = {};
  if (finalPrice !== 9.99) {
    finalObj = {
      initialDiscountPrice: finalPrice,
      initialDiscountObject: { type: "percentage", value: persent },
      discountPercent: persent,
    };
  }
  const price = billingInterval === "Year" ? monthlyPrice * 12 : monthlyPrice;
  if (plan?.id !== "Free") {
    return billingInterval === "Year" ? { price, monthlyPrice, ...finalObj } : { price, ...finalObj };
  } else {
    return {};
  }
};
export const dateWisePersent = (date, isFirstButtonActive) => {
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 9.99);
  return persent > 0 ? persent : false;
};

export const dateWisePriceBanner = (date, isFirstButtonActive) => {
  const day = differenceInDays(new Date(), new Date(date));
  const distanceText = formatDistanceToNow(new Date(date), { addSuffix: true });
  const billingInterval = isFirstButtonActive ? "Month" : "Year";
  const finalPrice = dateWisePrice(date, billingInterval);
  const persent = Math.round(100 - (finalPrice * 100) / 9.99);
};

export const AppList = [
  {
    title: "Webrex SEO: AI, Speed & Schema",
    tagLine: "Optimize your Store by align correct SEO to improve organic ranking, boost visibility",
    link: "https://apps.shopify.com/breadcrumbs-schemas?source=SEO-app",
    image: SeoLogo,
    starRate: 4.9,
    reviweCount: 437,
    popular: true,
    // hide: true,
  },
  {
    title: "WebRex Multi Announcement Bar",
    tagLine: "Boost sales with customizable bars for announcements, free shipping, and countdown timers",
    link: "https://apps.shopify.com/announcement-bar-with-slider?source=SEO-app",
    image: ABLogo,
    starRate: 4.9,
    reviweCount: 436,
    // hide: true,
    // popular: true,
  },
  {
    title: "Linkify ‑ Backlink SEO Booster",
    tagLine: "Supercharge SEO with top-tier backlinks from high DA sites. Boost rankings, traffic, and authority",
    link: "https://apps.shopify.com/linkify-app?source=SEO-app",
    image: LinkifyLogo,
    starRate: 5.0,
    reviweCount: 8,
    hide: true,
    // popular: true,
  },
  {
    title: "Webrex ‑ Currency Converter",
    tagLine: "Solution for your International Selling that will help to show converted prices, local currency",
    link: "https://apps.shopify.com/currency-converter-11?source=SEO-app",
    image: CCLogo,
    starRate: 4.9,
    reviweCount: 306,
    // hide: true,
    // popular: true,
  },
];
