import React, { useCallback, useContext, useRef, useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  Modal,
  Page,
  Text,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { backlinkSitesValue, initialBacklinkSitesValue } from "@/Assets/Mocks/BacklinkSites.mock";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import CommonTable from "../../Components/Common/CommonTable/CommonTable";

function BacklinkSites() {
  const { showToast } = useContext(ToastContext);
  const childRef = useRef();
  const fetch = useAuthenticatedFetch();
  const [formModalStatus, setformModalStatus] = useState(false);
  const formRef = useRef();
  const [formValues, setFormValues] = useState(initialBacklinkSitesValue);

  const handleClose = useCallback(() => setformModalStatus(!formModalStatus), [formModalStatus]);

  const handleFormModal = useCallback(
    async (type, rows) => {
      if (type === "Edit") {
        const step = rows.steps.map((step) => ({ steps: step }));
        rows["step"] = step;
        setFormValues(rows);
      } else {
        setFormValues(initialBacklinkSitesValue);
      }
      setformModalStatus(!formModalStatus);
    },
    [formModalStatus]
  );

  const submitForm = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    const rowSelect = [];
    for (var i of values.step) {
      if (i.steps == "") {
        i.steps = null;
      } else if (i.steps != null) {
        rowSelect.push(Object.values(i)[0]);
      }
    }
    values["steps"] = rowSelect;
    await fetch.post("admin/backlink", values);
    showToast(`Created successfully`);
    childRef.current.fetchData();
    setformModalStatus(false);
  }, []);

  const handleErrorSite = useCallback(async (row) => {
    if (row.isError == false) {
      row.isError = true;
    } else {
      row.isError = false;
    }
    await fetch.post(`admin/backlink`, row);
    showToast(`updated successfully`);
    childRef.current.fetchData();
  }, []);

  const rowData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      let step = [];
      row.steps.map((steps) => {
        return step.push(steps + "\n");
      });
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="200">
              <BlockStack gap="200">
                <Text>{row?.name}</Text>
              </BlockStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <Link url={row?.siteUrl} target="_blank">
                {row?.siteUrl}
              </Link>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <div style={{ whiteSpace: "pre-line" }}>{step}</div>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <Text>{row?.controller} </Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <Text>{row?.da}</Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <div className="button">
                {!row.isError ? (
                  <Button variant="primary" tone="warning" onClick={(e) => handleErrorSite(row)}>
                    No
                  </Button>
                ) : (
                  <Button variant="primary" tone="success" onClick={(e) => handleErrorSite(row)}>
                    Yes
                  </Button>
                )}
              </div>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="200">
              <div className="actionButton">
                <ButtonGroup>
                  <Button variant="plain" onClick={(e) => handleFormModal("Edit", row)}>
                    <Icon source={EditMinor} />
                  </Button>
                  <Button variant="plain" onClick={(e) => handleDelete(row)}>
                    <Icon source={DeleteMinor} />
                  </Button>
                </ButtonGroup>
              </div>
            </BlockStack>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  const handleDelete = useCallback(
    async (rows) => {
      const id = rows._id;
      await fetch.delete(`admin/backlink/${id}`);
      showToast("Delete successfully");
      childRef.current.fetchData();
    },
    [rowData]
  );

  return (
    <Page fullWidth>
      <Box padding="400">
        <BlockStack gap="200">
          <InlineStack gap="400">
            <Button
              primary
              size="medium"
              onClick={() => {
                handleFormModal("Add", initialBacklinkSitesValue);
              }}
            >
              Add Backlink Site
            </Button>
          </InlineStack>
          <CommonTable
            title="backlinks"
            queryPlaceholder="Search backlink by (name)"
            url={`/admin/backlinksites`}
            rowsData={rowData}
            isFilterVisible
            selectable={false}
            headings={[
              { title: "No" },
              { title: "Name" },
              { title: "siteUrl" },
              { title: "steps" },
              { title: "controller" },
              { title: "da" },
              { title: "Update error" },
              { title: "Action" },
            ]}
            ref={childRef}
            searchKey={["name"]}
            columnContentTypes={["numeric", "string", "string", "string", "string", "numeric", "string", "text"]}
            isAdd={false}
          />
        </BlockStack>
      </Box>
      <Modal
        open={formModalStatus}
        title={`add backlink site`}
        onClose={handleClose}
        primaryAction={{
          content: "Save",
          onAction: submitForm,
        }}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={formValues}
            formFields={backlinkSitesValue}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default BacklinkSites;
