export const backlinkSitesValue = [
  {
    id: "name",
    name: "name",
    label: "Site Name",
    type: "text",
    validated: true,
    errormsg: "Site Name is required",
  },
  {
    id: "siteUrl",
    name: "siteUrl",
    label: "Sites Url",
    type: "text",
    validated: true,
    errormsg: "Sites Url is required",
  },
  {
    id: "step",
    name: "step",
    label: "steps",
    nested: "array",
    minimum: 2,
    section: false,
    subfields: [
      {
        id: "steps",
        name: "steps",
        label: "Steps",
        validated: true,
        type: "text",
        errormsg: "Steps is required",
      },
    ],
  },
  {
    id: "controller",
    name: "controller",
    label: "Controller Name",
    type: "text",
    errormsg: "Controller Name is required",
  },
  {
    id: "da",
    name: "da",
    label: "DA",
    type: "number",
    errormsg: "DA is required",
  },
  {
    id: "category",
    name: "category",
    label: "Category",
    type: "text",
    errormsg: "Category is required",
  },
];

export const initialBacklinkSitesValue = {
  name: "",
  siteUrl: "",
  controller: "",
  step: ["", ""],
  da: "",
  category: "",
};
